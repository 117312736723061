import React, { useEffect, useState } from "react";
import { ReactComponent as Czechia } from '../imgs/flagIcons/Czechia-01.svg';
import { ReactComponent as France } from '../imgs/flagIcons/France-03.svg';
import { ReactComponent as GB } from '../imgs/flagIcons/GB-02.svg';
import { ReactComponent as Germany } from '../imgs/flagIcons/Germany-01.svg';
import { ReactComponent as Greece } from '../imgs/flagIcons/Greece-01.svg';
import { ReactComponent as Italy } from '../imgs/flagIcons/Italy-01.svg';
import { ReactComponent as Netherlands } from '../imgs/flagIcons/Netherlands-01.svg';
import { ReactComponent as Poland } from '../imgs/flagIcons/Poland-01.svg';
import { ReactComponent as Portugal } from '../imgs/flagIcons/Portugal-01.svg';
import { ReactComponent as Spain } from '../imgs/flagIcons/Spain-01.svg';
import { ReactComponent as Switzerland } from '../imgs/flagIcons/Switzerland-01.svg';
import { ReactComponent as Belgium } from '../imgs/flagIcons/Belgium-01.svg';
import { ReactComponent as Bosnia } from '../imgs/flagIcons/Bosnia-01.svg';
import { ReactComponent as Bulgaria } from '../imgs/flagIcons/Bulgaria-01.svg';
import { ReactComponent as Croatia } from '../imgs/flagIcons/Croatia-01.svg';
import { ReactComponent as Hungary } from '../imgs/flagIcons/Hungary-01.svg';
import { ReactComponent as Macedonia } from '../imgs/flagIcons/Macedonia-01.svg';
import { ReactComponent as Romania } from '../imgs/flagIcons/Romania-01.svg';
import { ReactComponent as Serbia } from '../imgs/flagIcons/Serbia-01.svg';
import { ReactComponent as Slovakia } from '../imgs/flagIcons/Slovakia-01.svg';
import { ReactComponent as Slovenia } from '../imgs/flagIcons/Slovenia-01.svg';

import styles from'./FlagIcons.module.css';

export default function FlagIcons({country,height,selected}){
    const countryArray = [
        {
            countryName: 'Czechia',
            svg: <Czechia />
        },
        {
            countryName: 'France',
            svg: <France />
        },
        {
            countryName: 'United Kingdom',
            svg: <GB />
        },
        {
            countryName: 'Germany',
            svg: <Germany />
        },
        {
            countryName: 'Greece',
            svg: <Greece />
        },
        {
            countryName: 'Italy',
            svg: <Italy />
        },
        {
            countryName: 'The Netherlands',
            svg: <Netherlands />
        },
        {
            countryName: 'Poland',
            svg: <Poland />
        },
        {
            countryName: 'Portugal',
            svg: <Portugal />
        },
        {
            countryName: 'Spain',
            svg: <Spain />
        },
        {
            countryName: 'Switzerland',
            svg: <Switzerland />
        },
        {
            countryName: 'Belgium',
            svg: <Belgium />
        },
        {
            countryName: 'Bosnia',
            svg: <Bosnia />
        },
        {
            countryName: 'Bulgaria',
            svg: <Bulgaria />
        },
        {
            countryName: 'Croatia',
            svg: <Croatia />
        },
        {
            countryName: 'Hungary',
            svg: <Hungary />
        },
        {
            countryName: 'North Macedonia',
            svg: <Macedonia />
        },
        {
            countryName: 'Romania',
            svg: <Romania />
        },
        {
            countryName: 'Serbia',
            svg: <Serbia />
        },
        {
            countryName: 'Slovakia',
            svg: <Slovakia />
        },
        {
            countryName: 'Slovenia',
            svg: <Slovenia />
        }
    ];

    const [countrySVG,setCountrySVG] = useState('');

    useEffect(() => {   
        const svgArray = [];
        countryArray.map((countryObj) => {
            if(countryObj.countryName === country){
                svgArray.push(countryObj.svg);
            }
        });
        setCountrySVG(svgArray[0]);
    },[country]);

    return (
        <div className={`${styles.container} ${selected? styles.selected : ''}`} style={{height: height, width: height, borderRadius: height}}>
            {countrySVG}
        </div>
    )
}