import React, { useContext, useState } from "react";
import styles from '../cookieBanner/CookieBanner.module.css';
import { ReactComponent as Cross } from '../../components/imgs/icons/cross-33.svg'
import PrivacyPolicyContent from "./PrivacyPolicyContent";
import { LoggedInContext } from "../providers/LoggedInContext";

export default function PrivacyPolicyViewer(){
    const [viewPolicy,setViewPolicy] = useState(false);

    const {isHiQ} = useContext(LoggedInContext);

    const toggleViewPolicy = () => {
        setViewPolicy((prev) => !prev);
    }
    return (
        <>
            <span className={styles.policyButton} onClick={toggleViewPolicy}>Privacy Policy</span>
            <div className={`${styles.container} ${viewPolicy? styles.block : styles.none} ${isHiQ? styles.hiq : ''}`}>
                <div className={styles.policyContainer}>
                    <div className={styles.header}>
                        <h3>Privacy Policy</h3>
                        <div onClick={toggleViewPolicy}>
                            <Cross />
                        </div>
                    </div>
                    <br/>
                    <PrivacyPolicyContent/>
                    <div className={styles.footer}>
                        <button type="button" className={styles.cookieButton} onClick={toggleViewPolicy}>Close</button>
                    </div>
                </div>
            </div>
        </>
    )
}