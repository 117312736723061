import React, { useContext , useEffect } from "react";
import styles from './Home.module.css';
import BrandCountrySelect from "../../brandCountrySelect/BrandCountrySelect";
import { useNavigate } from "react-router-dom";
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import HomeButton from "./HomeButtons";

export default function Home(){

    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);

    const navigate = useNavigate();

    const handleNavClick = (path) => {
        window.scrollTo(0,0);
        navigate('/'+path);
    }

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
    },[]);

    const guidelinesContent = [
        'Guidelines'
    ];
    const logosContent = {
        'Premio': [
            'Premio Logos',
            'Signage Assets',
            'Premio Tuning'
        ],
        'Vulco': [
            'Vulco Logos',
            'Signage Assets'
        ],
        'Super Service': [
            'Super Service Logos',
            'Signage Assets'
        ]
    };
    const marketingContent = {
        'Premio': [
            'Marketing Images',
            'Container Adverts',
            'Digital Adverts',
            'Digital Signage Adverts',
            'Print Adverts',
            'Web Adverts'
        ],
        'Vulco': [
            'Marketing Images',
            'Container Adverts',
            'Digital Adverts',
            'Digital Signage Adverts',
            'Print Adverts',
            'Web Adverts'
        ],
        'Super Service': [
            'Container Artworks',
            'Marketing Images'
        ]
    };
    const generalContent = {
        'Premio': [
            'Store Furniture Items',
            'Picture Frame Prints',
            'Icons & Favicons',
            'Connected Retail Solutions'
        ],
        'Vulco': [
            'Store Furniture Items',
            'Picture Frame Prints',
            'Icons & Favicons',
            'Connected Retail Solutions',
            'Vulco Pro'
        ],
        'Super Service': [
            'Picture Frame Prints',
            'Icons'
        ]
    };

    return(
        <div className={styles.container}>
            <div className={styles.buttonContainer}>
                <HomeButton handleNavClick={handleNavClick} content={guidelinesContent} pagePath={'guidelines'} name={'Guidelines'} tagLine={'Brand Specific'} imgPath={brand ==='Premio'? './imgs/home/Box__Premio_Guidelines.jpg' : './imgs/home/Box__Vulco_Guidelines.jpg'}/>
                <HomeButton handleNavClick={handleNavClick} content={logosContent[brand]} pagePath={'logosSignage'} name={'Logos & Signage Assets'} tagLine={'Country & Language Specific'} imgPath={brand === 'Premio'? './imgs/home/Box__Premio_Logos.jpg' : './imgs/home/Box__Vulco_Logos.jpg'}/>
                <HomeButton handleNavClick={handleNavClick} content={marketingContent[brand]} pagePath={'marketingAssets'} name={'Marketing Assets'} tagLine={'Country & Language Specific'} imgPath={brand === 'Premio'? './imgs/home/Box__Premio_Marketing.jpg' : './imgs/home/Box__Vulco_Marketing.jpg'}/>
                <HomeButton handleNavClick={handleNavClick} content={generalContent[brand]} pagePath={'generalAssets'} name={'General Assets'} tagLine={'Brand Specific'} imgPath={'./imgs/home/Box__General_Assets.jpg'}/>
            </div>
        </div>
    )
}