import React, { useContext, useEffect ,useState } from "react";
import styles from './UserCard.module.css';
import { LoggedInContext } from "../../providers/LoggedInContext";

export default function UserCardHeader(){

    const {user,isHiQ} = useContext(LoggedInContext);

    if (user.viewUsers === 1){
        if(user.editUsers === 1){
            return (
                <div className={`${styles.containerHeader} ${isHiQ? styles.hiq : ''}`}>
                    <div className={styles.nameEmailCount}>Name, Email & Defaults</div>
                    <div className={styles.permissions}>View Users</div>
                    <div className={styles.permissions}>Edit Users</div>
                    <div className={styles.permissions}>View All Brands</div>
                    <div className={styles.permissions}>View All Countries</div>
                    <div className={styles.permissions}>Europe Access</div>
                    <div className={styles.permissions}>UK Access</div>
                    <div>Options</div>
                    <div></div>
                    <div></div>
                </div>
            )
        } else {
            return (
                <div className={`${styles.conCon} ${isHiQ? styles.hiq : ''}`}>
                    <div className={`${styles.containerHeaderHeader} ${styles.viewOnly} ${isHiQ? styles.hiq : ''}`}>
                        <div></div>
                        <div className={`${styles.permissionsHeader} ${styles.eu}`}><strong>Europe</strong></div>
                        <div className={`${styles.permissionsHeader} ${styles.uk}`}><strong>UK</strong></div>
                    </div>
                    <div className={`${styles.containerHeader} ${isHiQ? styles.hiq : ''} ${styles.viewOnly}`}>
                        <div className={styles.nameEmailCount}>Name, Email & Default Country</div>
                        <div className={`${styles.permissions} ${styles.eu}`}>Europe Access</div>
                        <div className={`${styles.permissions} ${styles.eu}`}>All Brands</div>
                        <div className={`${styles.permissions} ${styles.eu}`}>All Countries</div>
                        <div className={`${styles.permissions} ${styles.uk}`}>UK Access</div>
                    </div>
                </div>
            )
        }
    }
}