import React, { useState , useEffect, createContext, useRef } from "react";

const LoggedInContext = createContext();

function LoggedInProvider({children}) {
    
    const [loggedIn,setLoggedIn] = useState(false);
    const [failedLogin,setFailedLogin] = useState(false);
    const [user,setUser] = useState({});
    const [failedLoginMessage,setFailedLoginMessage] = useState(false);
    const [loading,setLoading] = useState(false);
    const [linkObject,setLinkObject] = useState('');
    const [isHiQ,setIsHiQ] = useState(false);

    const backendEndpoint = 'https://box-backend-app-394558549977.europe-west1.run.app';
    // const backendEndpoint = 'http://localhost:5000';

    const handleLogin = async (username,password) => {
        setLoading(true);
        const headers = new Headers();
        headers.append('Content-Type','application/x-www-form-urlencoded');
        fetch(`${backendEndpoint}/login/${isHiQ? 'uk' : 'eu'}`,{
            method: 'POST',
            body: new URLSearchParams({username,password}),
            headers: headers,
            credentials: 'include',
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            if(recObj.message === 'Login successful'){
                setLoggedIn(true);
                setUser(recObj.user);
                setLinkObject(JSON.parse(recObj.linkObject.object));
                setFailedLogin(false)
            } else {
                setLoggedIn(false);
                setFailedLogin(true);
                if(recObj.message === 'Awaiting Authorisation'){
                    setFailedLoginMessage('Account created, awaiting authorisation');
                } else if (recObj.message === 'You have not been granted access'){
                    setFailedLoginMessage('You have not been granted access');
                } else {
                    setFailedLoginMessage('Incorrect email or password');
                }
            }
            setLoading(false);
        })
        .catch((err) => console.log(err));
    }

    const handleLogOut = () => {
        setLoading(true);
        const headers = new Headers();
        headers.append('Content-Type','application/x-www-form-urlencoded');
        fetch(backendEndpoint+'/logout',{
            method: 'GET',
            headers: headers,
            credentials: 'include',
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            handleLoginObject(recObj);
            setLoading(false);
        })
        .catch((err) => console.log(err));
    }

    const handleCheckLogin = () => {
        const headers = new Headers();
        headers.append('Content-Type','application/x-www-form-urlencoded');
        fetch(`${backendEndpoint}/login/${isHiQ? 'uk' : 'eu'}`,{
            method: 'GET',
            headers: headers,
            credentials: 'include',
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            handleLoginObject(recObj);
        })
        .catch((err) => console.log(err));
    }

    const handleLoginObject = (recObj) => {
        if(recObj.user){
            setLoggedIn(true);
            setUser(recObj.user);
        } else {
            setLoggedIn(false);
            setUser({});
        }
    }

    useEffect(() => {
        handleCheckLogin();
        const path = window.location.pathname;
        setIsHiQ(path.includes('hiq'));
    },[]);

    const handleGetLinkObject = () => {
        setLoading(true);
        const headers = new Headers();
        headers.append('Content-Type','application/x-www-form-urlencoded');
        fetch(backendEndpoint+'/linkObject',{
            method: 'GET',
            headers: headers,
            credentials: 'include',
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            setLoading(false);
            setLinkObject(JSON.parse(recObj.linkObject.object));
        })
        .catch((err) => console.log(err));
    }

    return (
        <LoggedInContext.Provider value={{loading,setLoading,failedLogin,backendEndpoint,loggedIn,user,handleLogin,handleLogOut,handleCheckLogin,failedLoginMessage,linkObject,handleGetLinkObject,isHiQ}}>
            {children}
        </LoggedInContext.Provider>
    )
}

export { LoggedInProvider, LoggedInContext };