import React, { useContext , useState , useEffect , useRef } from "react";
import styles from './UserIcon.module.css';
import { ReactComponent as Cross } from '../../components/imgs/icons/cross-33.svg'
import { LoggedInContext } from "../providers/LoggedInContext";
import { NavLink } from "react-router-dom";

export default function UserIconMobile(){

    const {user,handleLogOut} = useContext(LoggedInContext);

    return(
        <div className={styles.container}>
            <div className={`${styles.nameEmailCon}`}>
                <p className={styles.name}>{user.firstName} {user.lastName}</p>
                <p className={styles.email}>{user.email}</p>
            </div>
            <button className={styles.logout} onClick={handleLogOut}>Sign Out</button>
        </div>
    )
}