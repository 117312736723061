import React, { useContext, useEffect ,useState } from "react";
import styles from './Users.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import UserCard from "./UserCard";
import UserCardAwait from "./UserCardAwait";
import UserCardHeader from "./UserCardHeader";
import { LoggedInContext } from "../../providers/LoggedInContext";

export default function Users(){

    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);
    const {user,backendEndpoint,setLoading,isHiQ} = useContext(LoggedInContext);

    const [usersApproved,setUsersApproved] = useState([]);
    const [usersUnApproved,setUsersUnApproved] = useState([]);
    const [newFormOpen,setNewFormOpen] = useState(false);

    const [newFormData,setNewFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        pass: 'notARealPassword',
        viewUsers: false,
        editUsers: false,
        viewAllCountry: false,
        viewAllBrands: false,
        viewEU: false,
        viewUK: false,
        defaultCountry: '',
        defaultBrand: '',
        inviteCode: window.crypto.randomUUID()
    });

    const [emailAlreadyUsed,setEmailAlreadyUsed] = useState(false);

    useEffect(() => {
        if(!newFormData.viewEU && newFormData.viewUK){
            setNewFormData((prev) => ({...prev,defaultBrand: 'HiQ',defaultCountry: 'UK'}));
        }
    },[newFormData.viewEU,newFormData.viewUK]);

    useEffect(() => {
        setDisable(false);
        handleGetUsers();
        handlePageChange(window.location.pathname);
    },[]);

    const handleGetUsers = () => {
        handleGetUsersApproved();
    }
    const handleGetUsersApproved = async () => {
        setLoading(true);
        const headers = new Headers();
        headers.append('Content-Type','application/x-www-form-urlencoded');
        fetch(backendEndpoint+'/users',{
            method: 'GET',
            headers: headers,
            credentials: 'include',
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            console.log(recObj);
            sortUsersAuth(recObj);
            setLoading(false);
        })
        .catch((err) => console.log(err));

    }
    const sortUsersAuth = (objArray) => {
        if(objArray.msg != 'Not logged in'){
                let approvedArray = [];
            let unApprovedArray = [];
            objArray.map((obj) => {
                if(obj.stratAuth === 1){
                    approvedArray.push(obj);
                } else {
                    unApprovedArray.push(obj);
                }
            });
            setUsersApproved(approvedArray);
            setUsersUnApproved(unApprovedArray);
        }
    }

    const handleAddUser = async () => {
        setLoading(true);
        fetch(backendEndpoint+'/users',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(newFormData),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            handleGetUsers();
        })
        .catch((err) => console.log(err));
    }

    const checkEmailAlreadyInUse = async (email) => {
        fetch(backendEndpoint+'/emailCheck',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email}),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            if(recObj.status){
                setEmailAlreadyUsed(true);
            } else {
                setEmailAlreadyUsed(false);
            }
        })
        .catch((err) => console.log(err));
    }

    const handleChange = (e) => {
        setNewFormData((prev) => ({...prev,[e.target.name]: e.target.value}));
    }
    const handleCheckboxChange = (e) => {
        setNewFormData((prev) => ({...prev,[e.target.name]: e.target.checked}));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        //check form filled in correctly
        //check email hasn't been used
        if(newFormData.firstName === '' || newFormData.lastName === '' || newFormData.email === '' || (newFormData.defaultCountry === '' && newFormData.viewEU) || (newFormData.defaultBrand === '' && newFormData.viewEU) || (!newFormData.viewUK && !newFormData.viewEU)){
            alert(`All 'Personal Details' and 'Default Options' fields MUST be filled before submitting`);
        } else if((newFormData.defaultCountry === '' && newFormData.viewEU) || (newFormData.defaultBrand === '' && newFormData.viewEU) || (!newFormData.viewUK && !newFormData.viewEU)){
            alert(`All 'Personal Details' and 'Default Options' fields MUST be filled before submitting`);
        } else if (emailAlreadyUsed){
            alert('Email already in use');
        } else {
            handleAddUser();
            setNewFormOpen(false);
            setNewFormData({
                firstName: '',
                lastName: '',
                email: '',
                pass: 'notARealPassword',
                viewUsers: false,
                editUsers: false,
                viewAllCountry: false,
                viewAllBrands: false,
                viewEU: false,
                viewUK: false,
                defaultCountry: '',
                defaultBrand: '',
                inviteCode: window.crypto.randomUUID()
            });
        }

    }
    const handleCancel = () => {
        setNewFormOpen(false);
        setNewFormData({
            firstName: '',
            lastName: '',
            email: '',
            pass: 'notARealPassword',
            viewUsers: false,
            editUsers: false,
            viewAllCountry: false,
            viewAllBrands: false,
            viewEU: false,
            viewUK: false,
            defaultCountry: '',
            defaultBrand: '',
            inviteCode: window.crypto.randomUUID()
        });
    }

    const optionObject = {
        "Premio": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="Belgium">Belgium</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Czechia">Czechia</option>
            <option value="Germany">Germany</option>
            <option value="Greece">Greece</option>
            <option value="Hungary">Hungary</option>
            <option value="The Netherlands">The Netherlands</option>
            <option value="Poland">Poland</option>
            <option value="Romania">Romania</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Switzerland">Switzerland</option>
        </>,
        "Vulco": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="Bosnia">Bosnia</option>
            <option value="Croatia">Croatia</option>
            <option value="France">France</option>
            <option value="North Macedonia">North Macedonia</option>
            <option value="Portugal">Portugal</option>
            <option value="Serbia">Serbia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Spain">Spain</option>
        </>,
        "Super Service": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="Italy">Italy</option>
        </>,
        "HiQ": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="UK">UK</option>
        </>,
        "":
        <>
            <option hidden value="">Select a Brand first</option>
        </>

    }

    if (user.viewUsers === 1){
        if(user.editUsers === 1){
            return (
                <>
                    <div className={`${styles.newUser} ${newFormOpen? styles.newFormOpen : ''} ${isHiQ? styles.hiq : ''}`}>
                        <form onSubmit={handleSubmit} className={`${styles.changerPane} ${newFormOpen? styles.newFormOpen : ''}`}>
                            <h2>New User</h2>
                            <div className={styles.formInner}>
                                <div className={styles.personalCon}>
                                    <h3>Personal Details</h3>
                                    <div className={styles.nameInputs}>
                                        <label><p>First Name</p><input type="text" onChange={handleChange} name="firstName" value={newFormData.firstName}/></label>
                                        <label><p>Last Name</p><input type="text" onChange={handleChange} name="lastName" value={newFormData.lastName}/></label>
                                        <label><p>Email</p><input type="text" onChange={(e) => {handleChange(e);checkEmailAlreadyInUse(e.target.value);}} name="email" value={newFormData.email}/></label>
                                        <p className={styles.emailMsg}>{emailAlreadyUsed? 'Email already in use' : ''}</p>
                                    </div>
                                </div>
                                <div className={styles.rolesCon}>
                                    <h3>Roles</h3>
                                    <div className={styles.userRoles}>
                                        <label><p>Europe Access</p><input type="checkbox" name="viewEU" onChange={handleCheckboxChange} checked={newFormData.viewEU}/></label>
                                        <label><p>UK Access</p><input type="checkbox" name="viewUK" onChange={handleCheckboxChange} checked={newFormData.viewUK}/></label>
                                        <label><p>View Users</p><input type="checkbox" name="viewUsers" onChange={handleCheckboxChange} checked={newFormData.viewUsers}/></label>
                                        <label><p>Edit Users</p><input type="checkbox" name="editUsers" onChange={handleCheckboxChange} checked={newFormData.editUsers}/></label>
                                        <label><p>View All Brands</p><input type="checkbox" name="viewAllBrands" onChange={handleCheckboxChange} checked={newFormData.viewAllBrands} disabled={!newFormData.viewEU}/></label>
                                        <label><p>View All Countries</p><input type="checkbox" name="viewAllCountry" onChange={handleCheckboxChange} checked={newFormData.viewAllCountry} disabled={!newFormData.viewEU}/></label>
                                    </div>
                                </div>
                                <div className={styles.defaultCon}>
                                    <h3>Default Options (Europe Access)</h3>
                                    <div className={styles.defaults}>
                                        <label>
                                            <p>Default Brand</p>
                                            <select id="defaultBrand" type="text" name="defaultBrand" onChange={handleChange} value={newFormData.defaultBrand} disabled={!newFormData.viewEU}>
                                                <option hidden value="">Select a Brand</option>
                                                <option hidden value="HiQ">HiQ</option>
                                                <option value="Premio">Premio</option>
                                                <option value="Vulco">Vulco</option>
                                                <option value="Super Service">Super Service</option>
                                            </select>
                                        </label>
                                        <label>
                                            <p>Default Country</p>
                                            <select id="defaultCountry" type="text" name="defaultCountry" onChange={handleChange} value={newFormData.defaultCountry} disabled={!newFormData.viewEU || newFormData.defaultBrand === 'HiQ'}>
                                                {optionObject[newFormData.defaultBrand]}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.footer}>
                            <input onClick={handleCancel} className={styles.cancel} type="button" value="Cancel" /><input type="submit" value="Add User" />
                            </div>
                        </form>
                    </div>
                    <div className={`${styles.container} ${isHiQ? styles.hiq : ''}`}>
                        <button className={styles.addButton} onClick={() => setNewFormOpen(true)}>Add User</button>
                        <h2>Authorised</h2>
                        <br/>
                        <UserCardHeader/>
                        {usersApproved.map((userObj) => <UserCard key={userObj.id} userObj={userObj} handleGetUsers={handleGetUsers}/>)}
                        <br/>
                        <h2>Pending Authorisation</h2>
                        <br/>
                        <UserCardHeader/>
                        {usersUnApproved? usersUnApproved.map((userObj) => <UserCardAwait key={userObj.id} userObj={userObj} handleGetUsers={handleGetUsers}/>) : <h3>No users pending authorisation</h3>}
                    </div>
                </>
            )
        } else {
            return (
                <div className={styles.container}>
                    <br/>
                    <UserCardHeader/>
                    {usersApproved.map((userObj) => <UserCard userObj={userObj} handleGetUsers={handleGetUsers}/>)}
                </div>
            )
        }
    } else {
        return (
            <div className={styles.container} style={{height: '100vh'}}>
                <h2>You do not have access to view this page</h2>
            </div>
        )
    }
}