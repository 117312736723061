import React, { useContext , useEffect } from "react";
import styles from '../generalAssets/GeneralAssets.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import { useNavigate } from "react-router-dom";
import TabNavigation from "../../tabNavigation/TabNavigation";
import { LoggedInContext } from "../../providers/LoggedInContext";

export default function MarketingAssets(){
    const { linkObject , handleGetLinkObject } = useContext(LoggedInContext);
    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
        handleGetLinkObject();
    },[]);

    const getMALink = (brand,country) => {
        if (brand === 'Premio'){
            return linkObject.marketingAssets['Premio Standard'];
        } else if (brand === 'Vulco'){
            if(country === 'Spain'){
                return linkObject.marketingAssets['Spain'];
            } else if (country === 'Portugal'){
                return linkObject.marketingAssets['Portugal'];
            } else if (country === 'France'){
                return linkObject.marketingAssets['France'];
            } else {
                return linkObject.marketingAssets['Vulco Standard'];
            }
        } else if (brand === 'Super Service'){
            return linkObject.marketingAssets['Super Service'];
        }
    }

    return(
        <div className={styles.container}>
            <TabNavigation />
            {linkObject?  <iframe src={getMALink(brand,country)} frameborder="0" allowfullscreen webkitallowfullscreen msallowfullscreen></iframe> : ''}
        </div>
    )
}