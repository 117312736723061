import React, { useEffect, useState } from "react";
import styles from './CookieBanner.module.css';
import { ReactComponent as Cross } from '../../components/imgs/icons/cross-33.svg'
import CookiePolicyContent from "./CookiePolicyContent";

export default function CookieBanner(){

    const [cookieBannerVisible,setCookieBannerVisible] = useState(false);
    const [viewPolicy,setViewPolicy] = useState(false);
    const [isHiQ,setIsHiQ] = useState(false);

    useEffect(() => {
        const path = window.location.pathname;
        setIsHiQ(path.includes('hiq'));
        const hasDismissed = localStorage.getItem('cookieBannerDismissed');
        if(!hasDismissed){
            setCookieBannerVisible(true);
        }
    },[]);

    const toggleViewPolicy = () => {
        setViewPolicy((prev) => !prev);
    }
    const handleDismiss = () => {
        setCookieBannerVisible(false);
        localStorage.setItem('cookieBannerDismissed',true);
    }

    return (
        <div className={`${styles.container} ${cookieBannerVisible? styles.block : styles.none} ${isHiQ? styles.hiq : ''}`}>
            <div className={styles.bannerContainer}>
                <div>
                    <h3>Cookies</h3>
                    <p>This website uses only essential cookies to ensure it functions properly. By continuing to use this site, you accept our use of essential cookies.</p>
                </div>
                <div>
                    <button className={styles.cookieButton} onClick={toggleViewPolicy}>Cookie Policy</button>
                    <button className={`${styles.cookieButton} ${styles.accept}`} onClick={handleDismiss}>I Understand</button>
                </div>
            </div>
            <div className={`${styles.policyContainer} ${viewPolicy? styles.block : styles.none}`}>
                <div className={styles.header}>
                    <h3>Cookie Policy</h3>
                    <div onClick={toggleViewPolicy}>
                        <Cross />
                    </div>
                </div>
                <br/>
                <CookiePolicyContent />
                <div className={styles.footer}>
                    <button className={`${styles.cookieButton} ${styles.accept}`} onClick={handleDismiss}>I Understand</button>
                </div>
            </div>
        </div>
    )
}