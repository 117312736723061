import React, { useContext, useEffect } from "react";
import styles from '../cookies/CookiesInternal.module.css';
import FooterExternal from "../../footer/FooterExternal";
import CookiePolicyContent from "../../cookieBanner/CookiePolicyContent";
import { useNavigate } from "react-router-dom";
import { BrandCountryContext } from "../../providers/BrandCountryProvider";

export default function CookiesInternal(){

    const {brand,handlePageChange} = useContext(BrandCountryContext);

    useEffect(() => {
        handlePageChange(window.location.pathname);
    },[]);

    const imgObject =  {
        'Premio': 'url(./imgs/home/Box__Premio_Logos_High.jpg)',
        'Vulco': 'url(./imgs/home/Box__Vulco_Logos.jpg)',
        'Super Service': 'url(./imgs/home/Box_SuperService_Logos.jpg)'
    }

    return (
        <>
            <div className={styles.container}>
                <CookiePolicyContent />
            </div>
            <div className={styles.background} style={{backgroundImage: imgObject[brand]}}> </div>
        </>
    )
}