import React, { useContext, useEffect ,useState } from "react";
import styles from './UserCard.module.css';
import { LoggedInContext } from "../../providers/LoggedInContext";
import { ReactComponent as Tick } from '../../imgs/icons/Tick-01.svg'

export default function UserCard({userObj,handleGetUsers}){
    const {user,backendEndpoint,setLoading,isHiQ} = useContext(LoggedInContext);

    const [editMode,setEditMode] = useState(false);
    const [editForm,setEditForm] = useState(userObj);

    const handleDelete = () => {
        if(window.confirm(`Are you sure you want to permanently delete ${userObj.firstName} ${userObj.lastName}'s account?`)){
            fetch(backendEndpoint+'/users',{
                method: 'DELETE',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({id: userObj.id}),
                credentials: "include"
            })
            .then((res) => res.text())
            .then((data) => {
                handleGetUsers();
            })
            .catch((err) => console.log(err));
        } else {
        }
    }
    const handleChange = (e) => {
        setEditForm((prev) => ({...prev,[e.target.name]: e.target.value}));
    }
    const handleCheckboxChange = (e) => {
        setEditForm((prev) => ({...prev,[e.target.name]: e.target.checked}));
    }

    const toggleEdit = () => {
        setEditMode((prev) => !prev);
    }

    const handleCopyInviteLink = (inviteCode) => {
        navigator.clipboard.writeText("https://goodyear-retail-brand-hub.com/invite/"+inviteCode);
    }
    const optionObject = {
        "Premio": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="Belgium">Belgium</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Czechia">Czechia</option>
            <option value="Germany">Germany</option>
            <option value="Greece">Greece</option>
            <option value="Hungary">Hungary</option>
            <option value="The Netherlands">The Netherlands</option>
            <option value="Poland">Poland</option>
            <option value="Romania">Romania</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Switzerland">Switzerland</option>
        </>,
        "Vulco": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="Bosnia">Bosnia</option>
            <option value="Croatia">Croatia</option>
            <option value="France">France</option>
            <option value="North Macedonia">North Macedonia</option>
            <option value="Portugal">Portugal</option>
            <option value="Serbia">Serbia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Spain">Spain</option>
        </>,
        "Super Service": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="Italy">Italy</option>
        </>,
        "":
        <>
            <option hidden value="">Select a Brand first</option>
        </>

    }

    const handleSave = async () => {
        setLoading(true);
        fetch(backendEndpoint+'/users',{
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(editForm),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            handleGetUsers();
            toggleEdit();
        })
        .catch((err) => console.log(err));
    }

    const handleSendInvite = () => {
        setLoading(true);
        fetch(backendEndpoint+'/inviteSend',{
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: userObj.id,
                email: userObj.email,
                inviteCode: userObj.inviteCode
            }),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            handleGetUsers();
        })
        .catch((err) => console.log(err));
    }

    const inviteTexObj = {
        0: 'Send Invite',
        1: 'Re-send Invite'
    }

    if (user.viewUsers === 1){
        if(user.editUsers === 1){
            if(!editMode){
                return (
                    <div className={styles.container}>
                        <div className={styles.nameEmail}>
                            <div>{userObj.firstName+" "+userObj.lastName}</div>
                            <div className={styles.small}>{userObj.email}</div>
                            <div className={styles.small}>{userObj.defaultBrand} - {userObj.defaultCountry}</div>
                        </div>
                        <div className={styles.checkContainer}>{userObj.viewUsers === 1? <Tick /> : ''}</div>
                        <div className={styles.checkContainer}>{userObj.editUsers === 1? <Tick /> : ''}</div>
                        <div className={styles.checkContainer}>{userObj.viewAllBrands === 1? <Tick /> : ''}</div>
                        <div className={styles.checkContainer}>{userObj.viewAllCountry === 1? <Tick /> : ''}</div>
                        <div className={styles.checkContainer}>{userObj.viewEU === 1? <Tick /> : ''}</div>
                        <div className={styles.checkContainer}>{userObj.viewUK === 1? <Tick /> : ''}</div>
                        <div className={styles.inviteLink}>{userObj.inviteCode !== ''? <><p>{userObj.inviteCode}</p><button onClick={handleSendInvite}>{inviteTexObj[userObj.inviteSent]}</button></> : ''}</div>
                        <button onClick={toggleEdit}>Edit</button>
                        <button className={styles.delete} onClick={handleDelete}>Delete</button>
                    </div>
                )
            } else {
                return (
                    <div className={`${styles.container} ${styles.containerEdit}`}>
                         <div className={styles.nameEmail}>
                            <input type="text" onChange={handleChange} name="firstName" value={editForm.firstName}/>
                            <input type="text" onChange={handleChange} name="lastName" value={editForm.lastName}/>
                            <div className={styles.small}>{userObj.email}</div>
                        </div>
                        <div className={styles.checkContainer}>
                            <input type="checkbox" name="viewUsers" onChange={handleCheckboxChange} checked={editForm.viewUsers}/>
                        </div>
                        <div className={styles.checkContainer}>
                            <input type="checkbox" name="editUsers" onChange={handleCheckboxChange} checked={editForm.editUsers}/>
                        </div>
                        <div className={styles.checkContainer}>
                            <input type="checkbox" name="viewAllBrands" onChange={handleCheckboxChange} checked={editForm.viewAllBrands}/>
                        </div>
                        <div className={styles.checkContainer}>
                            <input type="checkbox" name="viewAllCountry" onChange={handleCheckboxChange} checked={editForm.viewAllCountry}/>
                        </div>
                        <div className={styles.checkContainer}>
                            <input type="checkbox" name="viewEU" onChange={handleCheckboxChange} checked={editForm.viewEU}/>
                        </div>
                        <div className={styles.checkContainer}>
                            <input type="checkbox" name="viewUK" onChange={handleCheckboxChange} checked={editForm.viewUK}/>
                        </div>
                        <div className={styles.options}>
                            <select id="defaultBrand" type="text" name="defaultBrand" onChange={handleChange} value={editForm.defaultBrand}>
                                <option hidden value="">Select a Brand</option>
                                <option value="Premio">Premio</option>
                                <option value="Vulco">Vulco</option>
                                <option value="Super Service">Super Service</option>
                            </select>
                            <button>Reset Password</button>
                            <select id="defaultCountry" type="text" name="defaultCountry" onChange={handleChange} value={editForm.defaultCountry}>
                                {optionObject[editForm.defaultBrand]}
                            </select>
                            <button>New Invite</button>
                        </div>
                        <div></div>
                        <div className={styles.options2}>
                            <button onClick={handleSave}>Save</button>
                            <button onClick={toggleEdit}>Cancel</button>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className={`${styles.container} ${styles.viewOnly} ${isHiQ? styles.hiq : ''}`}>
                    <div className={styles.nameEmail}>
                        <div>{userObj.firstName+" "+userObj.lastName}</div>
                        <div className={styles.small}>{userObj.email}</div>
                        <div className={styles.small}>{userObj.defaultBrand} - {userObj.defaultCountry}</div>
                    </div>
                    <div className={`${styles.checkContainer} ${styles.eu} ${styles.left}`}>{userObj.viewEU === 1? <Tick /> : ''}</div>
                    <div className={`${styles.checkContainer} ${styles.eu}`}>{userObj.viewAllBrands === 1? <Tick /> : ''}</div>
                    <div className={`${styles.checkContainer} ${styles.eu} ${styles.right}`}>{userObj.viewAllCountry === 1? <Tick /> : ''}</div>
                    <div className={`${styles.checkContainer} ${styles.uk}`}>{userObj.viewUK === 1? <Tick /> : ''}</div>
                </div>
            )
        }
    }
    
}