import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { LoggedInContext } from "../../providers/LoggedInContext";
import { BrandCountryContext } from "../../providers/BrandCountryProvider";

const PrivateRoutes = () => {
    const {loggedIn} = useContext(LoggedInContext);
    const {brand} = useContext(BrandCountryContext);

    if(loggedIn){
        return (
            <Outlet /> 
        )
    } else {
        return (
            <Navigate to="/login" />
        )
    }
}

export default PrivateRoutes;