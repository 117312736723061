import React, { useContext } from "react";
import styles from './LoadingScreen.module.css';
import { LoggedInContext } from "../providers/LoggedInContext";

export default function LoadingScreen(){
    const {loading,isHiQ} = useContext(LoggedInContext);
    return (
        <div className={`${styles.container} ${loading? styles.grid : styles.none} ${isHiQ? styles.hiq : ''}`}>
            <div className={styles.loaderContainer}>
                <div className={`${styles.dot} ${styles.dot1} ${styles.dotAnimation1}`}></div>
                <div className={`${styles.dot} ${styles.dot2} ${styles.dotAnimation2}`}></div>
                <div className={`${styles.dot} ${styles.dot3} ${styles.dotAnimation3}`}></div>
                <div className={`${styles.dot} ${styles.dot4} ${styles.dotAnimation4}`}></div>
                <div className={`${styles.dot} ${styles.dot5} ${styles.dotAnimation5}`}></div>
                <div className={`${styles.dot} ${styles.dot6} ${styles.dotAnimation6}`}></div>
                <div className={`${styles.dot} ${styles.dot7} ${styles.dotAnimation7}`}></div>
                <div className={`${styles.dot} ${styles.dot8} ${styles.dotAnimation8}`}></div>
            </div>
        </div>
    )
}