import React, { useContext , useState , useEffect } from "react";
import styles from './BrandButton.module.css';
import { BrandCountryContext } from "../providers/BrandCountryProvider";
import { ReactComponent as Premio } from '../imgs/logos/Premio.svg';
import { ReactComponent as Vulco } from '../imgs/logos/Vulco.svg';
import { ReactComponent as SuperService } from '../imgs/logos/SuperService.svg';
import { ReactComponent as HiQ } from '../imgs/logos/HiQ.svg';
import { ReactComponent as PremioMono } from '../imgs/logos/PremioMono.svg';
import { ReactComponent as VulcoMono } from '../imgs/logos/VulcoMono.svg';
import { ReactComponent as SuperServiceMono } from '../imgs/logos/SUperServiceMono2.svg';
import { ReactComponent as HiQmono } from '../imgs/logos/HiQmono.svg';
import { ReactComponent as Warning } from '../imgs/icons/warning-01.svg';


export default function BrandButton({brandName,handleSelectBrand}){
    const {brand,setBrand,country,setCountry} = useContext(BrandCountryContext)

    const brandArray = [
        {
            brandName: 'Premio',
            svgSelected: <Premio />,
            svgNotSelected: <Premio />
        },
        {
            brandName: 'Vulco',
            svgSelected: <Vulco />,
            svgNotSelected: <Vulco />
        },
        {
            brandName: 'Super Service',
            svgSelected: <SuperService />,
            svgNotSelected: <SuperService />
        },
        {
            brandName: 'HiQ',
            svgSelected: <HiQ />,
            svgNotSelected: <HiQmono />
        },
    ];

    const [brandSVG,setBrandSVG] = useState('');

    useEffect(() => {   
        const svgArray = [];
        brandArray.map((brandObj) => {
            if(brandObj.brandName === brandName){
                if(brand === brandName){
                    svgArray.push(brandObj.svgSelected);
                } else {
                    svgArray.push(brandObj.svgNotSelected);
                }
            }
        });
        setBrandSVG(svgArray[0]);
    },[brand]);

    const disclaimer = () => {
        if(brandName === 'Super Service'){
            return (
                <div className={`${styles.disclaimer} ${brandName === brand? styles.selected : ''}`}>
                    {/* <div className={styles.svgContainer2}>
                        {brandName === 'Super Service'? <Warning /> : ''}
                    </div> */}
                    {brandName === 'Super Service'? <p>Temporary Assets</p> : ''}
                </div>
            )
        }
    }

    return (
        <div className={`${styles.brandButton} ${brandName === brand? styles.selected : ''}`} style={{opacity: `${brandName === brand? 1 : 0.5}` }}onClick={() => handleSelectBrand(brandName)}>
            <div className={styles.svgContainer}>
                {brandSVG}
            </div>
            {/* <div className={`${styles.unselectedBar} ${brandName === brand? styles.selectedBar : ''}`}>
            </div> */}
            {disclaimer()}
        </div>
    )
}