import React, { useEffect, useRef, useState , useContext } from "react";
import styles from './Home.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";

export default function HomeButton({pagePath,name,tagLine,handleNavClick,imgPath,content}){

    const {brand} = useContext(BrandCountryContext);

    const contentList = useRef(null);

    const [loopScroll,setLoopScroll] = useState(false);
    const [overMobile,setOverMobile] = useState(false);

    const uniqueId = pagePath+'id';

    const scrollIfOverflow = () => {
        if(contentList.current){
            if(contentList.current.scrollWidth > contentList.current.offsetWidth){
                setLoopScroll(true);
                let extra = contentList.current.scrollWidth - contentList.current.offsetWidth;
                let move = Math.ceil(extra/100);
                contentList.current.style.setProperty("--container-width", `${contentList.current.offsetWidth}px`);
                contentList.current.style.setProperty("--content-width", `${contentList.current.scrollWidth}px`);
                contentList.current.style.setProperty("--move-duration", `${move}s`);
            } else {
                setLoopScroll(false);
            }
        }
    }
    useEffect(() => {
        scrollIfOverflow();
    },[]);



    const imgScrollOpacity = (parentElementId) => {
        const element = document.getElementById(parentElementId).getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        if(element.top <= viewportHeight*(0.1)){
            // setterFunction('0');
            setOverMobile(false);
        } else if (element.top >= viewportHeight*0.4) {
            // setterFunction('0');
            setOverMobile(false);
        } else if (element.top >= viewportHeight*(0.1) && element.top <= viewportHeight*(0.4)){
            setOverMobile(true);
        }
        
    }

    const scrollFunc = () => {
        imgScrollOpacity(uniqueId)
    }

    useEffect(() => {
        window.addEventListener("resize", scrollIfOverflow, false);
        window.addEventListener('scroll', scrollFunc);       
        return () => {
            window.removeEventListener("resize", scrollIfOverflow);
            window.removeEventListener('scroll', scrollFunc);
        }
    });

    const imgPathObject = {
        'guidelines' : {
            'Premio': './imgs/home/Box__Premio_Guidelines.jpg',
            'Vulco': './imgs/home/Box__Vulco_Guidelines.jpg',
            'Super Service': './imgs/home/Box_SuperService_Guidelines.jpg'
        },
        'logosSignage' : {
            'Premio': './imgs/home/Box__Premio_Logos.jpg',
            'Vulco': './imgs/home/Box__Vulco_Logos.jpg',
            'Super Service': './imgs/home/Box_SuperService_Logos.jpg'
        },
        'marketingAssets' : {
            'Premio': './imgs/home/Box__Premio_Marketing.jpg',
            'Vulco': './imgs/home/Box__Vulco_Marketing.jpg',
            'Super Service': './imgs/home/Box_SuperService_Marketing.jpg'
        },
        'generalAssets' : {
            'Premio': './imgs/home/Box__General_Assets.jpg',
            'Vulco': './imgs/home/Box__General_Assets.jpg',
            'Super Service': './imgs/home/Box_SuperService_General.jpg'
        },
    }

    return (
        <div id={uniqueId} className={`${styles.buttonMain} ${overMobile? styles.overMobile : ''}`} onClick={() => handleNavClick(pagePath)}>
            <div className={styles.imgContainer} style={{backgroundImage: `url(${imgPathObject[pagePath][brand]})`}}>   
            </div>
            <div className={styles.imgOverlay}>
                    {/* <div className={styles.expandingBar}></div> */}
                    <ul className={`${loopScroll ? styles.contentAnimation : ''}`} ref={contentList}>
                        {content.length > 0 ? content.map((text) => <li>{text}</li>) : ''}
                    </ul>
                </div>
            <div className={styles.imgOverlay2}></div>
            <div className={styles.expandingBar2}></div>
            <div className={styles.textContainer}>
                <h2>{name}</h2>
                <p>{tagLine}</p>
            </div>
        </div>
    )
}