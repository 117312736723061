import React, { useContext , useEffect } from "react";
import styles from '../generalAssets/GeneralAssets.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import { useNavigate } from "react-router-dom";
import TabNavigation from "../../tabNavigation/TabNavigation";
import { LoggedInContext } from "../../providers/LoggedInContext";

export default function LogosSignage(){
    const { linkObject , handleGetLinkObject } = useContext(LoggedInContext);
    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
        handleGetLinkObject();
    },[]);

    return(
        <div className={styles.container}>
            <TabNavigation />
            {linkObject?  <iframe src={linkObject.logosSignage[country]} frameborder="0" allowfullscreen webkitallowfullscreen msallowfullscreen></iframe> : ''}
        </div>
    )
}