import React, { useContext } from "react";
import styles from './TabNavigation.module.css';
import { NavLink } from "react-router-dom";
import { LoggedInContext } from "../providers/LoggedInContext";
import { BrandCountryContext } from "../providers/BrandCountryProvider";

export default function TabNavigation(){

    const handleNavClick = () => {
        window.scrollTo(0,0);
    }

    const {currentPage} = useContext(BrandCountryContext);

    return(
        <div className={styles.navTabBar}>
            <div className={`${styles.tab} ${currentPage === 'Guidelines'? styles.selected : ''}`}> 
                <NavLink to="/guidelines"  onClick={handleNavClick}>Guidelines</NavLink>
                <div className={styles.expandingBar}></div>
            </div>
            <div className={`${styles.tab} ${currentPage === 'Logos & Signage'? styles.selected : ''}`}> 
                <NavLink to="/logosSignage" onClick={handleNavClick}>Logos & Signage</NavLink>
                <div className={styles.expandingBar}></div>
            </div>
            <div className={`${styles.tab} ${currentPage === 'Marketing Assets'? styles.selected : ''}`}> 
                <NavLink to="/marketingAssets" onClick={handleNavClick}>Marketing Assets</NavLink>
                <div className={styles.expandingBar}></div>
            </div>
            <div className={`${styles.tab} ${currentPage === 'General Assets'? styles.selected : ''}`}> 
                <NavLink to="/generalAssets" onClick={handleNavClick}>General Assets</NavLink>
                <div className={styles.expandingBar}></div>
            </div>
        </div>
    )
}